import React from "react";

export default function transferSvg(props) {
  const { fill } = props;
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="30" height="30" viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill} stroke="none">
        <path d="M2184 5101 c-40 -24 -59 -76 -44 -117 6 -16 118 -136 248 -266 l237
        -238 -506 -507 c-543 -545 -561 -566 -628 -734 -53 -131 -65 -199 -65 -359 0
        -204 39 -343 141 -510 65 -106 392 -437 440 -446 69 -13 123 35 123 110 0 27
        -9 46 -34 73 -150 168 -180 403 -74 592 23 42 178 204 604 631 l573 575 243
        -241 c158 -157 253 -244 270 -248 39 -9 94 17 113 53 14 27 15 115 13 811 l-3
        780 -28 27 -27 28 -783 3 c-757 2 -783 2 -813 -17z m1444 -761 l2 -564 -187
        186 c-191 189 -215 207 -270 194 -19 -5 -232 -211 -664 -644 l-637 -637 -46
        -95 c-48 -99 -76 -190 -76 -247 0 -43 -12 -42 -34 5 -90 186 -104 380 -42 577
        51 159 55 164 648 760 364 367 549 559 553 578 14 55 -4 79 -193 270 l-186
        187 564 -2 565 -3 3 -565z"/>
        <path d="M3038 3183 c-59 -37 -65 -114 -14 -170 150 -168 180 -403 74 -592
        -23 -42 -178 -204 -604 -631 l-573 -575 -243 241 c-158 157 -253 244 -270 248
        -39 9 -94 -17 -113 -53 -14 -27 -15 -115 -13 -811 l3 -780 28 -27 27 -28 780
        -3 c696 -2 784 -1 811 13 36 19 62 74 53 113 -4 17 -91 112 -248 270 l-241
        242 501 503 c276 276 515 523 533 549 56 83 112 208 138 307 36 133 38 333 5
        464 -54 214 -127 328 -360 560 -151 151 -173 169 -208 173 -26 3 -48 -1 -66
        -13z m371 -611 c29 -57 59 -162 70 -249 14 -97 2 -208 -33 -318 -51 -159 -55
        -164 -648 -760 -364 -367 -549 -559 -553 -577 -14 -56 4 -80 193 -271 l186
        -187 -564 2 -565 3 -3 565 -2 564 187 -186 c191 -189 215 -207 271 -194 18 5
        231 211 663 644 l637 637 46 95 c48 99 76 190 76 248 0 40 13 35 39 -16z"/>
      </g>
    </svg>
  );
}
