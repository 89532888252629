import React from "react";

export default function withdrawSvg(props) {
  const { fill } = props;
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="30" height="30" viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={fill} stroke="none">
        <path d="M740 4609 c-134 -17 -276 -114 -337 -230 l-28 -54 -3 -1725 c-2
      -1699 -2 -1726 18 -1784 55 -165 195 -281 366 -306 94 -13 2824 -13 2919 0
      184 27 338 165 380 341 21 92 22 912 1 964 -38 90 -176 101 -232 18 -18 -26
      -19 -57 -24 -483 -5 -453 -5 -455 -28 -492 -13 -20 -40 -47 -60 -60 l-37 -23
      -1435 -3 c-1001 -2 -1446 0 -1472 8 -50 14 -97 56 -119 105 -18 38 -19 105
      -19 1508 0 807 2 1467 4 1467 3 0 17 -6 33 -12 22 -10 356 -14 1513 -18 1359
      -5 1488 -6 1515 -22 40 -22 81 -66 94 -101 7 -18 11 -137 11 -315 0 -167 4
      -303 10 -324 15 -53 66 -90 125 -90 53 0 88 20 115 65 19 30 20 52 20 337 0
      328 -5 371 -54 468 -72 142 -233 242 -388 242 l-48 0 0 53 c-1 89 -19 186 -46
      239 -59 117 -198 209 -344 228 -92 12 -2357 11 -2450 -1z m2445 -270 c24 -6
      61 -26 82 -44 38 -33 38 -33 42 -119 l3 -86 -1278 2 -1279 3 -43 23 c-53 28
      -85 76 -78 117 7 38 65 90 114 103 55 15 2380 15 2437 1z"/>
        <path d="M4243 3070 c-61 -37 -81 -116 -43 -177 21 -35 56 -55 225 -128 61
      -26 114 -50 118 -54 4 -4 -74 -32 -175 -61 -744 -216 -1385 -313 -1777 -269
      -114 13 -145 6 -186 -43 -30 -35 -34 -102 -9 -149 25 -49 79 -66 244 -79 430
      -33 1103 75 1862 301 110 33 202 58 204 56 2 -2 -33 -87 -77 -188 -45 -101
      -84 -202 -86 -225 -15 -126 143 -194 228 -98 9 11 82 169 163 353 164 375 169
      394 116 455 -17 18 -48 41 -69 50 -22 8 -173 74 -336 146 -315 138 -343 146
      -402 110z"/>
      </g>
    </svg>
  );
}
