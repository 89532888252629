import React from "react";

export default function investorSvg(props) {
  const { fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 512 470.17">
      <path fill={fill} d="M174.85 253.55c-11.46-10.2-20.51-17.55-22.43-38.71l-1.23.02c-2.84-.03-5.58-.69-8.14-2.14-4.09-2.33-6.98-6.34-8.93-10.86-4.13-9.48-7.39-34.41 2.99-41.55l-1.95-1.28-.22-2.77c-.39-5.01-.51-11.09-.59-17.45-.39-23.44-.87-48.78-19.71-54.47l-8.09-2.46 5.33-6.59c15.24-18.82 31.15-35.28 47.19-47.91C177.22 13.09 195.68 3.57 213.74.83c18.56-2.82 36.54 1.44 53.15 14.87 4.91 3.96 9.67 8.73 14.25 14.31 17.73 1.71 32.22 11.26 42.57 24.87 6.18 8.14 10.85 17.76 13.84 27.98 2.98 10.18 4.28 21.02 3.73 31.69-1.01 19.11-7.99 34.71-22.08 48.32 2.47.09 4.81.66 6.87 1.76 7.86 4.2 8.11 13.33 6.04 21-2.03 6.4-4.63 13.83-7.07 20.06-2.98 8.42-7.32 9.99-15.72 9.08-.44 20.82-10.05 26.55-23 38.78.53 31.35-112.17 27.66-111.47 0zm215.4 154.46c-6.53-.46-14.84-2.06-21.24-3.78v-20.07c8.49.7 29.4 2.48 37.11.73 5.8-1.31 5.15-10.44 1.33-11.59-4.57-1.42-14.18.28-21.91-1.95-3.93-1.15-7.26-2.87-9.97-5.16-6.06-5.19-8.19-13.69-8.19-21.38v-6.88c0-14.69 8.1-23.47 22.87-25.93v-9.32h21.02v8.92c6.53.51 14.63 2.17 21.11 3.75v20.09c-8.82-.77-25.55-2.36-33.93-.82-.74.12-1.41.33-1.97.6-3.46 1.63-3.32 11.03.89 12.14 2.75.73 8.41.35 11.43.35 3.03 0 5.86.3 8.45.88 2.62.61 5 1.5 7.09 2.7 8.81 5.01 12.69 12.36 12.69 22.13v6.87c0 4.1-.37 7.74-1.09 10.94-2.87 12.64-12.69 15.36-24.67 16.69v10.15h-21.02v-10.06zM169.01 279.37l33.54 88.06 16.86-48-8.27-9.03c-6.21-9.07-4.08-19.38 7.43-21.25 3.88-.62 8.26-.23 12.49-.23 4.47 0 9.45-.42 13.69.51 10.69 2.36 11.81 12.73 6.48 20.97l-8.27 9.03 16.87 48 30.32-88.06c7.45 6.72 15.22 12.14 23.14 16.65 3.42-4.7 7.19-9.12 11.28-13.21 19.94-19.93 47.38-32.24 77.63-32.24 30.3 0 57.77 12.31 77.63 32.17l1.01 1.1c19.27 19.78 31.16 46.79 31.16 76.54 0 30.29-12.31 57.76-32.17 77.62-19.86 19.86-47.33 32.17-77.63 32.17-30.25 0-57.69-12.31-77.56-32.17a110.172 110.172 0 0 1-14.53-17.88H0c0-124.09 94.34-73.52 169.01-140.75zm233.19-9.84c50.17 0 90.84 40.68 90.84 90.85 0 50.16-40.67 90.83-90.84 90.83-50.16 0-90.84-40.67-90.84-90.83 0-50.17 40.68-90.85 90.84-90.85z"/></svg>
  );
}
