import config from "config";
import usdt from "assets/img/USDT.png";

export const allowedMethods = [
  {
    gateway: "WIRE_TRANSFER",
    name: "Wire Transfer",
    image: "/img/payment-method/wire-transfer.png",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: true,
  },
  // {
  //   gateway: "OLX_FOREX",
  //   name: "Olx Forex",
  //   image: "/img/payment-method/olxforex.jpeg",
  //   allowed: ["fiatDeposit"],
  //   receipt: false,
  // },
  // {
  //   gateway: "PERFECT_MONEY",
  //   name: "Perfect Money",
  //   image: "/img/payment-method/perfect-money.png",
  //   allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
  //   receipt: true,
  // },
  // {
  //   gateway: "CRYPTO",
  //   name: "Crypto",
  //   image: "/img/payment-method/crypto.png",
  //   allowed: config.companyCryptoDeposit ? ["mt5Deposit", "fiatDeposit", "cryptoDeposit"] : [],
  //   receipt: false,
  // },
  // {
  //   gateway: "حواله بنكيه",
  //   name: "حواله بنكيه",
  //   image: "/img/payment-method/hawala_bank.png",
  //   allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
  //   receipt: true,
  // },
  // {
  //   gateway: "FINITIC_PAY",
  //   name: "Finitic Pay",
  //   image: usdt,
  //   allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
  //   receipt: false,
  // },
];