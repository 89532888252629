import logo from "assets/images/brands/image-20240619-152054.png";


export const clientName = "Best One FX";
export const developedBy = "Best One FX";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002";
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN || "http://localhost:3001";
export const companyName =  "Best One FX";
export const companyWebsite = "https://bestonefx.com/";
export const privacyPolicyLink = "#";
export const clientAgreementLink = "#";
export const sidebarLogo = logo;
export const smLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;
