import { AvForm } from "availity-reactstrap-validation";
import React from "react";
import {
  Col,
  Input,
  Label,
  Row
} from "reactstrap";

export default function Others({ t }) {
  return (
    <>
      <p className="text-muted">{t("Enter card information.")}</p>
      <Row>
        <AvForm>
          <Col xs={12}>
            <div className="mb-3">
              <Label
                htmlFor="example-date-input"
                className="form-label"
              >
                {t("Name")}
              </Label>
              <Input
                className="form-control"
                type="text"
                id="example-date-input"
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="mb-3">
              <Label
                htmlFor="example-date-input"
                className="form-label"
              >
                {t("Card Number")}
              </Label>
              <Input
                className="form-control"
                type="text"
                id="example-date-input"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label
                htmlFor="example-date-input"
                className="form-label"
              >
                {t("Expiry date")}
              </Label>
              <Input
                className="form-control"
                type="date"
                id="example-date-input"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label
                htmlFor="example-date-input"
                className="form-label"
              >
                {t("Security Code (CVV)")}
              </Label>
              <Input
                className="form-control"
                type="text"
                id="example-date-input"
              />
            </div>
          </Col>
        </AvForm>
      </Row>
    </>
  );
}
