import React from "react";

export default function inventorySvg(props) {
  const { fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
      <g id="surface1">
        <path fill={fill} d="M 22.042969 6.613281 C 22.042969 5.847656 21.441406 5.222656 20.695312 5.222656 L 19.085938 5.222656 C 19.039062 5.117188 18.984375 5.015625 18.925781 4.914062 C 18.558594 4.238281 18.03125 3.636719 17.371094 3.128906 C 16.058594 2.117188 14.324219 1.5625 12.496094 1.5625 C 10.667969 1.5625 8.933594 2.117188 7.625 3.128906 C 6.960938 3.640625 6.4375 4.238281 6.066406 4.914062 C 6.011719 5.015625 5.960938 5.121094 5.910156 5.226562 L 4.300781 5.226562 C 3.554688 5.226562 2.953125 5.847656 2.953125 6.613281 L 2.953125 8.96875 L 22.039062 8.96875 Z M 8.050781 5.222656 C 8.234375 5.003906 8.453125 4.800781 8.703125 4.609375 C 9.703125 3.835938 11.050781 3.414062 12.5 3.414062 C 13.941406 3.414062 15.292969 3.839844 16.296875 4.609375 C 16.546875 4.800781 16.761719 5.003906 16.949219 5.222656 Z M 22.503906 19.066406 L 2.488281 19.066406 L 2.953125 9.511719 L 22.042969 9.511719 Z M 22.585938 20.75 L 2.410156 20.75 L 2.476562 19.488281 L 22.519531 19.488281 Z M 22.640625 21.980469 C 22.683594 22.773438 22.070312 23.4375 21.300781 23.4375 L 3.691406 23.4375 C 2.921875 23.4375 2.308594 22.773438 2.351562 21.980469 L 2.390625 21.171875 L 22.601562 21.171875 Z M 22.640625 21.980469 "/>
      </g>
    </svg>
  );
}
