import React from "react";

export default function documentSvg(props) {
  const { fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="25px" height="25px" viewBox="0 0 25 25" version="1.1">
      <g id="surface1">
        <path fill={fill} d="M 13.078125 7.277344 L 17.863281 7.277344 L 17.863281 8.433594 L 13.078125 8.433594 Z M 13.078125 9.183594 L 17.863281 9.183594 L 17.863281 10.34375 L 13.078125 10.34375 Z M 13.078125 11.105469 L 17.863281 11.105469 L 17.863281 12.261719 L 13.078125 12.261719 Z M 7.121094 7.277344 L 12.121094 7.277344 L 12.121094 12.265625 L 7.121094 12.265625 Z M 7.121094 12.980469 L 17.863281 12.980469 L 17.863281 14.136719 L 7.121094 14.136719 Z M 7.121094 14.789062 L 7.121094 15.945312 L 17.863281 15.945312 L 17.863281 14.757812 L 7.121094 14.757812 Z M 7.121094 16.566406 L 17.863281 16.566406 L 17.863281 17.722656 L 7.121094 17.722656 Z M 21.050781 0 L 4.40625 0 C 3.308594 0 2.414062 0.894531 2.414062 1.996094 L 2.414062 23.003906 C 2.414062 24.105469 3.308594 25 4.40625 25 L 20.578125 25 C 21.679688 25 22.574219 24.105469 22.574219 23.003906 L 22.574219 1.523438 C 22.574219 0.679688 21.890625 0 21.050781 0 Z M 20.863281 13.898438 L 20.863281 23.003906 C 20.863281 23.164062 20.738281 23.289062 20.578125 23.289062 L 4.40625 23.289062 C 4.25 23.289062 4.121094 23.164062 4.121094 23.003906 L 4.121094 1.996094 C 4.121094 1.835938 4.25 1.710938 4.40625 1.710938 L 16.636719 1.710938 C 16.972656 1.710938 17.523438 1.960938 17.738281 2.214844 L 18.140625 2.675781 C 18.398438 2.972656 18.652344 3.265625 18.910156 3.558594 C 19.253906 3.953125 19.59375 4.355469 19.933594 4.75 L 20.378906 5.273438 C 20.628906 5.558594 20.863281 6.195312 20.863281 6.574219 Z M 20.863281 13.898438 "/>
      </g>
    </svg>
  );
}
