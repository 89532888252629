import React from "react";

export default function walletSvg(props) {
  const { fill } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25">
      <path fill={fill} strokeWidth="1.5"  d="M15.318.25a6 6 0 0 1 5.998 5.998v7.177a6 6 0 0 1-5.998 5.998H6.675a6 6 0 0 1-5.998-5.998V6.248A6 6 0 0 1 6.675.25zm0 1.5H6.675a4.5 4.5 0 0 0-4.498 4.498v7.177a4.5 4.5 0 0 0 4.498 4.498h8.643a4.5 4.5 0 0 0 4.498-4.498l-.001-.28-3.298.001a3.444 3.444 0 0 1-3.442-3.441 3.442 3.442 0 0 1 3.253-3.436l.189-.005h3.297l.001-.015a4.5 4.5 0 0 0-4.286-4.493l-.212-.005zm4.497 6.013h-3.297a1.943 1.943 0 0 0-1.943 1.941 1.944 1.944 0 0 0 1.79 1.936l.152.006 3.297-.001V7.763zm-2.839 1.13a.75.75 0 0 1 .102 1.493l-.102.007h-.312a.75.75 0 0 1-.102-1.493l.102-.007h.312zm-5.614-4.105a.75.75 0 0 1 .102 1.493l-.102.007H5.963a.75.75 0 0 1-.102-1.493l.102-.007h5.399z" />
    </svg>
  );
}
