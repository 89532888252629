/* CHATS */
export const GET_CHATS = "GET_CHATS";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const GET_CHATS_FAIL = "GET_CHATS_FAIL";

/* GROUPS */
export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL";

/* CONTACTS */
export const GET_CONTACTS = "GET_CONTACTS";
export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAIL = "GET_CONTACTS_FAIL";

/* MESSAGES */
export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS";
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL";

export const POST_ADD_MESSAGE = "POST_ADD_MESSAGE";
export const POST_ADD_MESSAGE_SUCCESS = "POST_ADD_MESSAGE_SUCCESS";
export const POST_ADD_MESSAGE_FAIL = "POST_ADD_MESSAGE_FAIL";
