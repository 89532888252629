export const FETCH_WITHDRAWALS_GATEWAYS_START = "FETCH_WITHDRAWALS_GATEWAYS_START";
export const FETCH_WITHDRAWALS_GATEWAYS_SUCCESS = "FETCH_WITHDRAWALS_GATEWAYS_SUCCESS";

export const ADD_WITHDRAWAL_REQUESTED = "ADD_WITHDRAWAL_REQUESTED";
export const ADD_WITHDRAWAL_SUCCESS = "ADD_WITHDRAWAL_SUCCESS";
export const ADD_WITHDRAWAL_FAIL = "ADD_WITHDRAWAL_FAIL";

export const GET_IB_DEPOSITS_START = "GET_IB_DEPOSIT_START";
export const GET_IB_DEPOSITS_SUCCCESS = "GET_IB_DEPOSIT_SUCCCESS";

export const GET_IB_WITHDRAWS_START = "GET_IB_WITHDRAWS_START";
export const GET_IB_WITHDRAWS_SUCCCESS = "GET_IB_WITHDRAWS_SUCCCESS";

export const IB_INTERNAL_TRANSFER_START = "IB_INTERNAL_TRANSFER_START";
export const IB_INTERNAL_TRANSFER_SUCCESS = "IB_INTERNAL_TRANSFER_SUCCESS";
export const IB_INTERNAL_TRANSFER_FAILED = "IB_INTERNAL_TRANSFER_FAILED";
